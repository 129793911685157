<template>
  <div class="theme-global">
    <CartHeader />
    <GlobalSnackbars />
    <main id="main" class="text-primitives-black h-full w-full md:mb-0">
      <slot />
    </main>
    <CartFooter />
    <GlobalSlides />
  </div>
</template>
