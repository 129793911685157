<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const localePath = useLocalePath()
const { getCountryName } = useCountryHelper()
const { country } = useRouteHelper()
const { openDialog: openContactUs } = useDialog(
  SIDEBAR_IDS.cartContactUsSideSlide
)

const openContacts = () => {
  openContactUs()
}
</script>
<template>
  <div
    class="border-b-primitives-grey-100 md:h-18 positive-padding flex h-[3.75rem] w-full items-center justify-between"
  >
    <NuxtLink
      :to="localePath('/')"
      :title="`${$ts('accessibility.goTo')} ${$ts('accessibility.homepage')}`"
    >
      <DefaultIconsGlobalMenuLogo class="h-5 md:h-6" aria-hidden="true" />
    </NuxtLink>
    <div class="gap-md flex w-fit items-center">
      <div class="text-book-7 line-clamp-2 text-ellipsis">
        <span>
          {{ $ts('cartPage.header.shippingTo') }}
          {{ getCountryName(country) }}
        </span>
      </div>
      <button
        :aria-label="$ts('contacts')"
        aria-haspopup="dialog"
        @click="openContacts"
      >
        <DefaultIconsSupport class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  </div>
  <LoginSidebar />
</template>
